
















import { defineComponent } from '@vue/composition-api';
import { NavigationBar, AppFooter } from '@/components';

export default defineComponent({
  name: 'BaseLayout',
  components: {
    NavigationBar,
    AppFooter,
  },
});
